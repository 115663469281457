export const routes = {
	home: '/',
	hotel: '/hotel',
	medical: '/medical',
	refund: '/refund',
	serviceJoin: '/service-join',
	service: '/service',
	faq: '/faq',
	application: '/application',
	trial: '/trial',
	applicationComplete: '/application/complete',
};

export const refundRoutes = {
	taxRefund: '/refund',
	medical: '/refund/medical',
	globalPayment: '/refund/global-payment',
};

export const serviceJoinRoutes = {
	serviceJoin: '/service-join',
	ktp: '/service-join/ktp',
};

export const serviceRoutes = {
	franchisee: '/service',
	franchiseeDetail: '/service/franchisee/detail',
	policy: '/service/policy',
	policyDetail: '/service/policy/detail',
};
