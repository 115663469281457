import ApplyNowIconStep1 from 'assets/serviceJoin/ApplyNowStep1.png';
import ApplyNowIconStep2 from 'assets/serviceJoin/ApplyNowStep2.png';
import ApplyNowIconStep3 from 'assets/serviceJoin/ApplyNowStep3.png';

import PC_ApplyNowImgStep1 from 'assets/serviceJoin/pc/ImgApplyNowStep1.png';
import PC_ApplyNowImgStep2_1 from 'assets/serviceJoin/pc/ImgApplyNowStep2-1.png';
import PC_ApplyNowImgStep2_2 from 'assets/serviceJoin/pc/ImgApplyNowStep2-2.png';
import PC_ApplyNowImgStep3_1 from 'assets/serviceJoin/pc/ImgApplyNowStep3-1.png';
import PC_ApplyNowImgStep3_2 from 'assets/serviceJoin/pc/ImgApplyNowStep3-2.png';
import PC_ApplyNowImgStep3_3 from 'assets/serviceJoin/pc/ImgApplyNowStep3-3.png';

import T_ApplyNowImgStep1 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep1.png';
import T_ApplyNowImgStep2_1 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep2-1.png';
import T_ApplyNowImgStep2_2 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep2-2.png';
import T_ApplyNowImgStep3_1 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep3-1.png';
import T_ApplyNowImgStep3_2 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep3-2.png';
import T_ApplyNowImgStep3_3 from 'assets/serviceJoin/tablet/t_ImgApplyNowStep3-3.png';

import M_ApplyNowImgStep1 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep1.png';
import M_ApplyNowImgStep2_1 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep2-1.png';
import M_ApplyNowImgStep2_2 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep2-2.png';
import M_ApplyNowImgStep3_1 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep3-1.png';
import M_ApplyNowImgStep3_2 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep3-2.png';
import M_ApplyNowImgStep3_3 from 'assets/serviceJoin/mobile/m_ImgApplyNowStep3-3.png';

import KTPProxyIconStep1 from 'assets/serviceJoin/KTPProxyApplyStep1.png';
import KTPProxyIconStep2 from 'assets/serviceJoin/KTPProxyApplyStep2.png';
import KTPProxyIconStep3 from 'assets/serviceJoin/KTPProxyApplyStep3.png';

import PC_KTPProxyImgStep1 from 'assets/serviceJoin/pc/ImgKTPProxyApplyStep1.png';
import PC_KTPProxyImgStep2 from 'assets/serviceJoin/pc/imgKTPProxyApplyStep2.png';
import PC_KTPProxyImgStep3_1 from 'assets/serviceJoin/pc/imgKTPProxyApplyStep3-1.png';
import PC_KTPProxyImgStep3_2 from 'assets/serviceJoin/pc/imgKTPProxyApplyStep3-2.png';

import T_KTPProxyImgStep1 from 'assets/serviceJoin/tablet/t_ImgKTPProxyApplyStep1.png';
import T_KTPProxyImgStep2 from 'assets/serviceJoin/tablet/t_ImgKTPProxyApplyStep2.png';
import T_KTPProxyImgStep3_1 from 'assets/serviceJoin/tablet/t_ImgKTPProxyApplyStep3-1.png';
import T_KTPProxyImgStep3_2 from 'assets/serviceJoin/tablet/t_ImgKTPProxyApplyStep3-2.png';

export const ServiceJoinContent = {
  iconStep: {
    ApplyNow: {
      title: '면세판매장 ',
      titleStrength: '신청 어렵지 않습니다!',
      items: [
        { title: 'STEP 1', icon: ApplyNowIconStep1, description: '홈텍스 접속' },
        {
          title: 'STEP 2',
          icon: ApplyNowIconStep2,
          description: '"면세판매장 지정신청" 검색',
        },
        {
          title: 'STEP 3',
          icon: ApplyNowIconStep3,
          description: '면세판매장 지정신청 정보 입력',
        },
      ],
    },
    KTPProxyApply: {
      title: '어려우실 땐 ',
      titleStrength: 'KTP에 대리 신청하세요!',
      items: [
        { title: 'STEP 1', icon: KTPProxyIconStep1, description: 'KTP APP 로그인' },
        { title: 'STEP 2', icon: KTPProxyIconStep2, description: '"대리 신청" 하기' },
        {
          title: 'STEP 3',
          icon: KTPProxyIconStep3,
          description: '면세판매장 지정신청 정보 입력',
        },
      ],
    },
  },
  imageStep: {
    ApplyNow: {
      items: [
        {
          bgColor: '#F4F8FF',
          title: '홈텍스 접속',
          titleBgColor: '#5089F8',
          titleStrength: 'STEP 1 ',
          link: 'https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index_pp.xml',
          linkDesc: '홈텍스 바로가기',
          pcImg: PC_ApplyNowImgStep1,
          tImg: T_ApplyNowImgStep1,
          mImg: M_ApplyNowImgStep1,
          subTitle: '홈텍스 접속',
        },
        {
          bgColor: '#E9F0FE',
          title: '"면세판매점 지정 신청" 검색',
          titleBgColor: '#246CF6',
          titleStrength: 'STEP 2 ',
          pcImg: PC_ApplyNowImgStep2_1,
          tImg: T_ApplyNowImgStep2_1,
          mImg: M_ApplyNowImgStep2_1,
          subTitle: '"면세판매점 지정 신청" 검색',
        },
        {
          bgColor: '#E9F0FE',
          pcImg: PC_ApplyNowImgStep2_2,
          tImg: T_ApplyNowImgStep2_2,
          mImg: M_ApplyNowImgStep2_2,
          subTitle: '홈텍스 메뉴 클릭',
        },
        {
          bgColor: '#D3E2FD',
          title: '정보 입력',
          titleBgColor: '#1856CD',
          titleStrength: 'STEP 3 ',
          pcImg: PC_ApplyNowImgStep3_1,
          tImg: T_ApplyNowImgStep3_1,
          mImg: M_ApplyNowImgStep3_1,
          subTitle: '면세판매장 지정신청 정보 입력',
        },
        {
          bgColor: '#D3E2FD',
          pcImg: PC_ApplyNowImgStep3_2,
          tImg: T_ApplyNowImgStep3_2,
          mImg: M_ApplyNowImgStep3_2,
          subTitle: '관련 서식 작성 후 파일 업로드',
        },
        {
          bgColor: '#D3E2FD',
          pcImg: PC_ApplyNowImgStep3_3,
          tImg: T_ApplyNowImgStep3_3,
          mImg: M_ApplyNowImgStep3_3,
          subTitle: '면세판매장 지정신청 완료',
          pcDescription: '평균적으로 신청 후 3-5일 이내(영업일 기준) 지정이 완료됩니다.',
          tDescription: '평균적으로 신청 후 3-5일 이내(영업일 기준)\n지정이 완료됩니다.',
        },
      ],
    },
    KTPProxyApply: {
      items: [
        {
          bgColor: '#F4F8FF',
          title: 'KTP APP 로그인',
          titleBgColor: '#5089F8',
          titleStrength: 'STEP 1 ',
          pcImg: PC_KTPProxyImgStep1,
          tImg: T_KTPProxyImgStep1,
          imgBackgroundColor: '#7CA7FA',
          subTitle: 'KTP APP 로그인',
        },
        {
          bgColor: '#E9F0FE',
          title: '"대리신청" 하기',
          titleBgColor: '#246CF6',
          titleStrength: 'STEP 2 ',
          pcImg: PC_KTPProxyImgStep2,
          tImg: T_KTPProxyImgStep2,
          imgBackgroundColor: '#7CA7FA',
          subTitle: '앱 내에 "대리신청하기" 클릭',
        },
        {
          bgColor: '#D3E2FD',
          title: '면세판매장 지정신청 정보 입력',
          titleBgColor: '#1856CD',
          titleStrength: 'STEP 3 ',
          pcImg: PC_KTPProxyImgStep3_1,
          tImg: T_KTPProxyImgStep3_1,
          imgBackgroundColor: '#7CA7FA',
          subTitle: '면세판매장 지정신청 정보 입력',
        },
        {
          bgColor: '#D3E2FD',
          pcImg: PC_KTPProxyImgStep3_2,
          tImg: T_KTPProxyImgStep3_2,
          imgBackgroundColor: '#7CA7FA',
          subTitle: '면세판매장 지정신청 완료',
          pcDescription: '평균적으로 신청 후 6~7일 이내(영업일 기준) 지정이 완료됩니다.',
          tDescription: '평균적으로 신청 후 6~7일 이내(영업일 기준)\n지정이 완료됩니다.',
        },
      ],
    },
  },
};
