import styled from 'styled-components';
import { useLocation } from 'react-router';

import TopSelectButton from './TopSelectButton';
import { MedicalVideoBanner } from 'constants/Medical';

function VideoBanner() {
  const { pathname } = useLocation();

  const currentBannerInfo = MedicalVideoBanner[pathname] || {};

  return (
    <VideoBannerWrapper id='video-banner'>
      <Video autoPlay loop muted pathname={pathname}>
        <source src={currentBannerInfo.video} type='video/mp4' />
      </Video>
      <OverlayWrapper>
        <OverlayTopWrapper>
        </OverlayTopWrapper>
        <TitleWrapper>
          <SubTitle fontSize='2rem'>{currentBannerInfo.subTitle}</SubTitle>
          <Title>
            {currentBannerInfo.title} <TextStrength>KTP</TextStrength>
          </Title>
        </TitleWrapper>
        <TopSelectButton />
      </OverlayWrapper>
    </VideoBannerWrapper>
  );
}

export default VideoBanner;

const VideoBannerWrapper = styled.section`
  position: relative;
  width: 100%;
  margin-bottom: 10rem;
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 2rem;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 1rem;
  }
`;

const Video = styled.video<{ pathname: string }>`
  width: 100vw;
  height: 82vh;
  object-fit: cover;
  filter: ${(props) => (props.pathname === '/medical' ? 'brightness(50%)' : 'brightness(70%)')};
  @media ${(props) => props.theme.tablet} {
    height: auto;
  }
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1024px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  padding: 4.6rem 0.75rem 10.38rem;

  @media ${(props) => props.theme.tablet} {
    padding: 1.37rem 0.75rem 10.38rem;
  }
`;

const OverlayTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 3.75rem;
  font-weight: 800;
  line-height: 150%;
  letter-spacing: -0.075rem;
  @media ${(props) => props.theme.tablet} {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.04rem;
  }
`;

const TextStrength = styled.span`
  color: #7ca7fa;
`;

const SubTitle = styled.h3<{ fontSize: string }>`
  color: #ffffff;
  font-size: ${(props) => props.fontSize};
  font-weight: 700;
  line-height: 150%;
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;
