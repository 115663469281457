import React from 'react';

import MainContainerLayout from 'components/_common/MainContainerLayout';
import BrowserTitle from 'components/_common/BrowserTitle';
import ApplyNowFlow from 'components/ServiceJoin/ApplyNowFlow';

function ApplyNow() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='면세판매장 직접신청'
        description='외국인관광객 면세판매장 지정신청'
        url='https://www.ktaxpay.com/service-join'
      />
      <ApplyNowFlow/>
    </MainContainerLayout>
  );
}

export default ApplyNow;
