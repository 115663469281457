import { INQUIRY_STEPS } from 'constants/Medical';
import styled from 'styled-components';
import { Title48 } from 'styles';
import ICON_CALL from 'assets/medical/inquiryGuide/icon_call.png';

function InquiryGuide() {
	return (
		<Wrapper>
			<InnerWrapper>
				<Title>
					외국인 환자 유치기관 등록부터 서비스 도입까지 친절하게 상담해드립니다.
				</Title>
				<ListWrapper>
					{INQUIRY_STEPS.map((step) => {
						return (
							<Card>
								<BlueTxt>{step.step}</BlueTxt>
								<img src={step.icon} alt='' width={52} />
								<BlueTxt>{step.title}</BlueTxt>
							</Card>
						);
					})}
				</ListWrapper>
				<InquiryNumber>
					<img src={ICON_CALL} alt='' width={28} />
					<Number>도입문의 02-6275-8011</Number>
				</InquiryNumber>
			</InnerWrapper>
		</Wrapper>
	);
}

export default InquiryGuide;

const Wrapper = styled.section`
	background: #f4f8ff;
	padding: 200px 0;
	@media ${(props) => props.theme.tablet} {
		padding: 100px 0;
	}
	@media ${(props) => props.theme.mobile} {
		padding: 64px 0;
	}
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 64px;
	max-width: 1024px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
`;

const Title = styled(Title48)`
	color: #246cf6;
`;

const ListWrapper = styled.ul`
	display: flex;
	gap: 40px;
	max-width: 1080px;
	padding: 10px;
	overflow-x: scroll;
	scrollbar-width: none;
	&:-webkit-scrollbar {
		display: none;
	}
	@media ${(props) => props.theme.tablet} {
		gap: 20px;
	}
	@media ${(props) => props.theme.mobile} {
		gap: 16px;
	}
`;

const Card = styled.li`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex: 1;
	min-width: 140px;
	padding: 40px 0;
	border-radius: 12px;
	border: 1px solid var(--Mono-05, #f5f6f7);
	background: var(--Mono-00, #fff);
	box-shadow: 0px 0px 16px 0px #dae7ff;
`;

const BlueTxt = styled.span`
	color: #246cf6;
	text-align: center;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: -0.48px;
	@media ${(props) => props.theme.tablet} {
		font-size: 1.3rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 1rem;
	}
`;

const InquiryNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	padding: 10px 24px;
	background: #246cf6;
	width: fit-content;
	margin: auto;
	gap: 3px;
`;

const Number = styled.span`
	margin-top: 2px;
	color: #fff;
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.56px;
`;
