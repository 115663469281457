import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import bottomArrowImg from 'assets/home/TopSection/bottomArrow.png';
import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import arrowRightIcon from 'assets/common/arrow_right_white.png';
import arrowLeftIcon from 'assets/common/arrow_left_white.png';
import Icon from 'components/_common/Icon';
import revenueImg from 'assets/home/TopSection/revenue.png';

import ktpBanner1280Webp from 'assets/home/TopSection/banner/ktp1280Webp.webp';
import ktpBanner1280 from 'assets/home/TopSection/banner/ktp1280.png';
import ktpBanner1024Webp from 'assets/home/TopSection/banner/ktp1024Webp.webp';
import ktpBanner1024 from 'assets/home/TopSection/banner/ktp1024.png';
import ktpBanner576Webp from 'assets/home/TopSection/banner/ktp576Webp.webp';
import ktpBanner576 from 'assets/home/TopSection/banner/ktp576.png';
import ktpBanner360Webp from 'assets/home/TopSection/banner/ktp360Webp.webp';
import ktpBanner360 from 'assets/home/TopSection/banner/ktp360.png';

import globalBanner1280Webp from 'assets/home/TopSection/banner/global1280Webp.webp';
import globalBanner1280 from 'assets/home/TopSection/banner/global1280.png';
import globalBanner1024Webp from 'assets/home/TopSection/banner/global1024Webp.webp';
import globalBanner1024 from 'assets/home/TopSection/banner/global1024.png';
import globalBanner576Webp from 'assets/home/TopSection/banner/global576Webp.webp';
import globalBanner576 from 'assets/home/TopSection/banner/global576.png';
import globalBanner360Webp from 'assets/home/TopSection/banner/global360Webp.webp';
import globalBanner360 from 'assets/home/TopSection/banner/global360.png';

import medicalBanner1280Webp from 'assets/home/TopSection/banner/medical1280Webp.webp';
import medicalBanner1280 from 'assets/home/TopSection/banner/medical1280.png';
import medicalBanner1024Webp from 'assets/home/TopSection/banner/medical1024Webp.webp';
import medicalBanner1024 from 'assets/home/TopSection/banner/medical1024.png';
import medicalBanner576Webp from 'assets/home/TopSection/banner/medical576Webp.webp';
import medicalBanner576 from 'assets/home/TopSection/banner/medical576.png';
import medicalBanner360Webp from 'assets/home/TopSection/banner/medical360Webp.webp';
import medicalBanner360 from 'assets/home/TopSection/banner/medical360.png';

import hotelktp1280Webp from 'assets/home/TopSection/banner/hotelktp1280Webp.webp';
import hotelktp1280 from 'assets/home/TopSection/banner/hotelktp1280.png';
import hotelktp1024Webp from 'assets/home/TopSection/banner/hotelktp1024Webp.webp';
import hotelktp1024 from 'assets/home/TopSection/banner/hotelktp1024.png';
import hotelktp576Webp from 'assets/home/TopSection/banner/hotelktp576Webp.webp';
import hotelktp576 from 'assets/home/TopSection/banner/hotelktp576.png';
import hotelktp360Webp from 'assets/home/TopSection/banner/hotelktp360Webp.webp';
import hotelktp360 from 'assets/home/TopSection/banner/hotelktp360.png';

import { checkWebpSupport } from 'utils/checkWebpSupport';
import useDeviceCheck from 'hooks/useDeviceCheck';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function SelectTopSection() {
  const { pathname } = useLocation();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentMaxIndex, setCurrentMaxIndex] = useState(3);
  const slideRef = useRef<any>(null);
  let intervalTimeOut = useRef<any>(0);
  let timeOutRef = useRef<any>(null);
  const [isDisplay, setIsDisplay] = useState(true);
  const { isTablet } = useDeviceCheck();

  const handleScroll = () => {
    const currentPosition = window.scrollY || window.pageYOffset;
    setIsDisplay(currentPosition <= 0 ? true : false);
  };
  useEffect(() => {
    if (pathname === '/hotel') {
      setCurrentMaxIndex(4);
    } else {
      setCurrentMaxIndex(3);
    }
  }, [pathname]);

  useEffect(() => {
    intervalTime();
  }, [currentIndex]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', initPosition);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', initPosition);
    };
  }, []);

  const initPosition = () => {
    clearInterval(intervalTimeOut.current);
    if (!slideRef.current) return;
    setCurrentIndex(1);
    slideRef.current!.style.transform = `translateX(calc(${-100}vw))`;
    intervalTime();
  };

  const intervalTime = () => {
    clearInterval(intervalTimeOut.current);
    let time: number = pathname === '/hotel' ? (currentIndex === 1 ? 10000 : 6000) : isMobile ? 1500 : 6000;
    intervalTimeOut.current = setInterval(() => {
      onClickRight();
    }, time);
  };

  const onClickRight = async () => {
    if (!slideRef.current) return;
    if (currentIndex === currentMaxIndex + 1) {
      return;
    }
    moveSlide(currentIndex + 1);
    clearInterval(intervalTimeOut.current);
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      if (currentIndex === currentMaxIndex) {
        setCurrentIndex(1);
        moveSlide(1);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
      if (!slideRef.current) return;
      slideRef.current!.style.transition = '0s';
      slideRef.current!.style.pointerEvents = 'all';
    }, 500);
  };

  const onClickLeft = () => {
    if (!slideRef.current) return;
    moveSlide(currentIndex - 1);
    // 모바일 랜딩페이지 슬라이드(무한x)
    if (currentIndex === 0) {
      return;
    }
    clearInterval(intervalTimeOut.current);
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      if (currentIndex === 1) {
        setCurrentIndex(currentMaxIndex);
        moveSlide(currentMaxIndex);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
      slideRef.current!.style.transition = '0s';
      slideRef.current!.style.pointerEvents = 'all';
    }, 500);
  };

  const moveSlide = (value: number) => {
    if (!slideRef.current) return;
    slideRef.current!.style.transition = `${currentMaxIndex}00ms`;
    slideRef.current!.style.transform = `translateX(calc(${-value * 100}vw))`;
  };

  return (
    <Section>
      <>
        <RevenueImg src={revenueImg} alt='국세청 승인 환급창구.' />
        <ArrowIconContainer isLeft isDisplay={isDisplay} onClick={onClickLeft}>
          <Icon imgUrl={arrowLeftIcon} width={isTablet ? '24px' : '48px'} height={isTablet ? '24px' : '48px'} alt='왼쪽 화살표.' />
        </ArrowIconContainer>
        <ArrowIconContainer isRight isDisplay={isDisplay} onClick={onClickRight}>
          <Icon imgUrl={arrowRightIcon} width={isTablet ? '24px' : '48px'} height={isTablet ? '24px' : '48px'} alt='오른쪽 화살표.' />
        </ArrowIconContainer>
        {pathname === '/1' ? (
          <SlideList ref={slideRef} id='home-banner'>
            <SlideContainer to='/'>
              <KTPSlide />
            </SlideContainer>
            <SlideContainer to='/'>
              <GlobalSlide />
            </SlideContainer>
            <SlideContainer to='/'>
              <KTPSlide />
            </SlideContainer>
            <SlideContainer to='/'>
              <GlobalSlide />
            </SlideContainer>
          </SlideList>
        ) : pathname === '/hotel' ? (
          <SlideList ref={slideRef} id='home-banner'>
            <SlideContainer to='/hotel'>
              <MedicalSlide />
            </SlideContainer>
            <SlideContainer to='/hotel'>
              <HotelSlide />
            </SlideContainer>
            <SlideContainer to='/hotel'>
              <GlobalSlide />
            </SlideContainer>
            <SlideContainer to='/hotel'>
              <MedicalSlide />
            </SlideContainer>
            <SlideContainer to='/hotel'>
              <KTPSlide />
            </SlideContainer>
            <SlideContainer to='/hotel'>
              <HotelSlide />
            </SlideContainer>
          </SlideList>
        ) : (
          <SlideList ref={slideRef} id='home-banner'>
            <SlideContainer to='/refund/medical'>
              <MedicalSlide />
            </SlideContainer>
            <SlideContainer to='/'>
              <KTPSlide />
            </SlideContainer>
            <SlideContainer to='/refund/global-payment'>
              <GlobalSlide />
            </SlideContainer>
            <SlideContainer to='/refund/medical'>
              <MedicalSlide />
            </SlideContainer>
            <SlideContainer to='/'>
              <KTPSlide />
            </SlideContainer>
          </SlideList>
        )}

        {/* PC만 적용 */}
        <StepList isDisplay={isDisplay}>
          <Step isActive={currentIndex % currentMaxIndex === 1} />
          <Step isActive={currentIndex % currentMaxIndex === 2} />
          {pathname === '/hotel' && <Step isActive={currentIndex % currentMaxIndex === 3} />}
          <Step isActive={currentIndex % currentMaxIndex === 0} />
        </StepList>
      </>
    </Section>
  );
}
const Section = styled.section`
  overflow: hidden;
  position: relative;
`;
const SlideList = styled.div`
  display: flex;
  width: 500vw;
  height: 100vh;
  transform: translateX(-100vw);
  @media ${(props) => props.theme.bannerMobile} {
    padding-top: 50px;
    height: 100dvh;
  }
`;
const SlideContainer = styled(Link)``;
const KTPSlide = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: contain;
  content: url(${checkWebpSupport(ktpBanner1280Webp, ktpBanner1280)});
  @media ${(props) => props.theme.bannerDesktop} {
    content: url(${checkWebpSupport(ktpBanner1024Webp, ktpBanner1024)});
  }
  @media ${(props) => props.theme.bannerTablet} {
    content: url(${checkWebpSupport(ktpBanner576Webp, ktpBanner576)});
  }
  @media ${(props) => props.theme.bannerMobile} {
    content: url(${checkWebpSupport(ktpBanner360Webp, ktpBanner360)});
  }
`;
const GlobalSlide = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: contain;
  background: #e0e0e0;
  content: url(${checkWebpSupport(globalBanner1280Webp, globalBanner1280)});
  @media ${(props) => props.theme.bannerDesktop} {
    content: url(${checkWebpSupport(globalBanner1024Webp, globalBanner1024)});
  }
  @media ${(props) => props.theme.bannerTablet} {
    content: url(${checkWebpSupport(globalBanner576Webp, globalBanner576)});
  }
  @media ${(props) => props.theme.bannerMobile} {
    content: url(${checkWebpSupport(globalBanner360Webp, globalBanner360)});
  }
`;
const MedicalSlide = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: contain;
  background: #e9f0fe;
  content: url(${checkWebpSupport(medicalBanner1280Webp, medicalBanner1280)});
  @media ${(props) => props.theme.bannerDesktop} {
    content: url(${checkWebpSupport(medicalBanner1024Webp, medicalBanner1024)});
  }
  @media ${(props) => props.theme.bannerTablet} {
    content: url(${checkWebpSupport(medicalBanner576Webp, medicalBanner576)});
  }
  @media ${(props) => props.theme.bannerMobile} {
    content: url(${checkWebpSupport(medicalBanner360Webp, medicalBanner360)});
  }
`;
const HotelSlide = styled.img`
  width: 100vw;
  height: 100%;
  object-fit: contain;
  background: #e2dacf;
  content: url(${checkWebpSupport(hotelktp1280Webp, hotelktp1280)});
  @media ${(props) => props.theme.bannerDesktop} {
    content: url(${checkWebpSupport(hotelktp1024Webp, hotelktp1024)});
  }
  @media ${(props) => props.theme.bannerTablet} {
    content: url(${checkWebpSupport(hotelktp576Webp, hotelktp576)});
  }
  @media ${(props) => props.theme.bannerMobile} {
    content: url(${checkWebpSupport(hotelktp360Webp, hotelktp360)});
  }
`;
const StepList = styled.div<{ isDisplay: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 250ms ease-in;
  opacity: ${(props) => (props.isDisplay ? 1 : 0)};
  @media ${(props) => props.theme.mobile} {
    display: none;
  }
`;
const Step = styled.div<{ isActive: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? '#246cf6' : 'gray')};
  pointer-events: ${(props) => (props.isActive ? 'none' : 'auto')};
`;
const ArrowContainer = styled(FlexWrap)`
  flex-direction: column;
  align-items: center;
  bottom: 20px;
  left: 50%;
  z-index: 100;
  gap: 12px;
  transition: all 250ms ease-in;
`;
const ArrowImg = styled.img`
  animation-name: arrowDown;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  z-index: 100;
  @keyframes arrowDown {
    from {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 6px);
    }
    to {
      transform: translate(0, 0px);
    }
  }
`;
const BottomContainer = styled(FlexWrap)`
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15px;
  @media ${(props) => props.theme.desktop} {
    display: none;
  }
`;
const ArrowIconContainer = styled.div<{
  isLeft?: boolean;
  isRight?: boolean;
  isDisplay: boolean;
}>`
  width: 48px;
  height: 48px;
  position: fixed;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  transition: all 250ms ease-in;
  /* opacity: 0.85; */
  background-color: rgba(229, 230, 232, 0.75);
  cursor: pointer;
  border-radius: 50%;
  display: ${(props) => (props.isDisplay ? 'auto' : 'none')};
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isRight &&
    css`
      right: 48px;
      @media ${(props) => props.theme.tablet} {
        right: 24px;
      }
    `}
  ${(props) =>
    props.isLeft &&
    css`
      left: 48px;
      @media ${(props) => props.theme.tablet} {
        left: 24px;
      }
    `}
    @media ${(props) => props.theme.tablet} {
    width: 24px;
    height: 24px;
  }
`;
const RevenueImg = styled.img`
  top: 100px;
  left: 40px;
  position: absolute;
  width: 30rem;
  height: 2.68rem;
  z-index: 10;
  object-fit: contain;
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    top: 95%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 16px;
  }
`;
export default SelectTopSection;
