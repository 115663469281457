import React, { useEffect, useRef, useState } from 'react';

function useObserve() {
	const ref = useRef(null) as React.MutableRefObject<null | HTMLDivElement>;
	const [isActive, setIsActive] = useState(false);

	const observerCallback: IntersectionObserverCallback = (entries) => {
		if (entries[0].isIntersecting) {
			setIsActive(true);
		}
	};

	useEffect(() => {
		let observer: IntersectionObserver;
		if (ref.current) {
			observer = new IntersectionObserver(observerCallback, {
				threshold: 0.6,
			});
			observer.observe(ref.current);
		}
		return () => observer && observer.disconnect();
	}, []);

	return { isActive, ref };
}

export default useObserve;
