import { SURGERY_LEFT, SURGERY_RIGHT } from 'constants/serviceIntro.const';
import React from 'react';
import styled from 'styled-components';
import IMG_INTRO from 'assets/medical/taxRefundIntro.png';

function MedicalTaxRefundIntro() {
	return (
		<Wrapper>
			<Container>
				<Title>병원도 택스리펀 도입이 가능한가요?</Title>
				<Content>
					택스리펀은 외국인 환자 유치 활성화를 위한 제도로
					<br />
					치과, 성형외과, 피부과 등에서 17가지 비과세 시술을 택스리펀 할 수
					있어요!
				</Content>
				<NotiWrapper>
					<Notification>
						*비급여(부가세 포함)시술 항목 중 미용 성형을 목적으로 하는 시술만
						가능합니다.
					</Notification>
					<SurgeryWrapper>
						<ul>
							{SURGERY_LEFT.map((surgery) => {
								return (
									<ListWrapper>
										<li>{surgery.first}</li>
										<li>{surgery.second}</li>
										<li>{surgery.third}</li>
									</ListWrapper>
								);
							})}
						</ul>
						<ul>
							{SURGERY_RIGHT.map((surgery) => {
								return (
									<ListWrapper>
										<li>{surgery.first}</li>
										<li>{surgery.second}</li>
										<li>{surgery.third}</li>
									</ListWrapper>
								);
							})}
						</ul>
					</SurgeryWrapper>
					<ImgIntro src={IMG_INTRO} alt='' />
				</NotiWrapper>
			</Container>
		</Wrapper>
	);
}

export default MedicalTaxRefundIntro;

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1190px;
	width: 100%;
	margin: 0 auto;
	padding: 6.75rem 7.75rem 6.25rem;
	@media ${(props) => props.theme.tablet} {
		padding: 3.35rem 1.25rem 3.35rem;
	}
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	white-space: pre-wrap;
`;

const Title = styled.h2`
	font-size: 3rem;
	line-height: 150%;
	text-align: center;
	font-weight: 700;
	margin-bottom: 16px;
	@media ${(props) => props.theme.tablet} {
		font-size: 2.5rem;
		margin-top: 2rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 2rem;
	}
`;

const Content = styled.p`
	color: #3a3b3e;
	text-align: center;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 150%; /* 27px */
	@media ${(props) => props.theme.tablet} {
		font-size: 1.1rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 1rem;
	}
`;

const NotiWrapper = styled.div`
	margin-top: 32px;
`;

const Notification = styled.p`
	color: #80848a;
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.28px;
`;

const SurgeryWrapper = styled.div`
	display: flex;
	margin-top: 8px;
	@media ${(props) => props.theme.mobile} {
		flex-direction: column;
	}
	ul {
		flex: 1;
	}
`;

const ListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	li {
		flex: 1;
		border: 0.5px solid var(--Mono-10, #e5e6e8);
		width: 100px;
		padding: 10px 0;
		font-size: 0.9rem;
		font-weight: 400;
		color: #5f6165;
		word-break: keep-all;
		text-align: center;
	}
`;

const ImgIntro = styled.img`
	/* width: 100%;
	max-width: 1000px; */
	margin-top: 120px;
	width: 100%;
	height: 25.4rem;
	border-radius: 0.75rem;
	object-fit: cover;
	@media ${(props) => props.theme.tablet} {
		height: 20rem;
	}
	@media ${(props) => props.theme.mobile} {
		height: 12.5rem;
	}
`;
