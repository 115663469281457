import styled from 'styled-components';

import CloseImg from 'assets/medical/CloseImg.png';

function CloseBox() {
	return (
		<Section>
			<ResponsiveImg src={CloseImg} />
			<Wrapper>
				<Text>{'KTP가 글로벌 K-뷰티 시장의\n든든한 파트너가 되겠습니다'}</Text>
			</Wrapper>
		</Section>
	);
}

export default CloseBox;

const Section = styled.section`
	position: relative;
	background: url(CloseImg) lightgray -18.571px -200.188px / 101.066% 123.073%
		no-repeat;
`;

const ResponsiveImg = styled.img`
	display: block;
	width: 100%;
	max-height: 100vh;
`;

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 1024px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #fff;
	text-align: center;
`;

const Text = styled.p`
	color: #ffffff;
	text-shadow: 1px 3px 20px rgba(0, 0, 0, 0.3);
	font-size: 3.75rem;
	font-weight: 800;
	line-height: 150%;
	letter-spacing: -0.075rem;
	white-space: pre-line;
	@media ${(props) => props.theme.tablet} {
		font-size: 2.5rem;
		letter-spacing: -0.05rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 1.75rem;
		font-weight: 700;
		letter-spacing: -0.035rem;
	}
`;
