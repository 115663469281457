import React, { useCallback, useEffect, useState } from 'react';
import {
	FieldError,
	UseFormHandleSubmit,
	UseFormRegister,
	DeepPartial,
	DeepMap,
	SubmitHandler,
	UseFormGetValues,
} from 'react-hook-form';
import styled, { css } from 'styled-components';
import Input from 'components/_common/Input';
import Textarea from 'components/_common/Textarea';
import { IApplicationFormValues } from 'model/types';
import FlexWrap from 'components/_common/FlexWrap';
import useDeviceCheck from 'hooks/useDeviceCheck';

interface IProps {
	register: UseFormRegister<IApplicationFormValues>;
	handleSubmit: UseFormHandleSubmit<IApplicationFormValues>;
	errors: DeepMap<DeepPartial<IApplicationFormValues>, FieldError>;
	onSubmit: SubmitHandler<IApplicationFormValues>;
	isTermSelected: boolean;
	onToggleTerm: () => void;
	getValues: UseFormGetValues<IApplicationFormValues>;
	taxRefund?: boolean;
}

function FormSection({
	register,
	handleSubmit,
	errors,
	onSubmit,
	isTermSelected,
	onToggleTerm,
	getValues,
	taxRefund = false,
}: IProps) {
	const [isBtnActive, setIsBtnActive] = useState(false);
	const { isTablet } = useDeviceCheck();
	const onChange = useCallback(() => {
		const { name, phoneNumber, email, franchiseeName, inquiry } = getValues();
		setIsBtnActive(
			Boolean(
				name &&
					phoneNumber &&
					email &&
					franchiseeName &&
					inquiry &&
					isTermSelected
			)
		);
	}, [isTermSelected]);

	useEffect(() => {
		onChange();
	}, [isBtnActive, onChange]);

	return (
		<Form
			onSubmit={handleSubmit(onSubmit)}
			method='POST'
			onChange={onChange}
			taxRefund={taxRefund}
		>
			<StyledFlexWrap taxRefund={taxRefund}>
				<Input
					{...register('name', {
						required: '성함은 필수 항목입니다.',
					})}
					placeholder='성함을 입력해주세요.'
					type='text'
					label='성함'
					isRequired
					margin={[0, 0, 30]}
					error={errors.name}
				/>
				<Input
					{...register('phoneNumber', {
						required: '연락처는 필수 항목입니다.',
						pattern: {
							value: /^\d+$/,
							message: '연락처는 숫자만 기입해주세요.',
						},
						minLength: {
							value: 7,
							message: '연락처 형식에 맞지 않습니다. 다시 입력해주세요.',
						},
						maxLength: {
							value: 11,
							message: '연락처 형식에 맞지 않습니다. 다시 입력해주세요.',
						},
					})}
					placeholder='연락 가능한 번호를 입력해주세요.'
					type='number'
					label='연락처'
					isRequired
					margin={[0, 0, 30]}
					error={errors.phoneNumber}
				/>
			</StyledFlexWrap>
			<StyledFlexWrap taxRefund={taxRefund}>
				<Input
					{...register('email', {
						required: '이메일은 필수 항목입니다.',
						pattern: {
							value: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
							message: '이메일 형식이 올바르지 않습니다.',
						},
					})}
					placeholder='이메일을 입력해주세요.'
					type='text'
					label='이메일'
					isRequired
					margin={[0, 0, 30]}
					error={errors.email}
				/>
				<Input
					{...register('franchiseeName', {
						required: '매장(상호)명은 필수 항목입니다.',
					})}
					placeholder='매장명 또는 상호명을 입력해주세요.'
					type='text'
					label='매장(상호)명'
					isRequired
					margin={[0, 0, 30]}
					error={errors.franchiseeName}
				/>
			</StyledFlexWrap>
			<Textarea
				{...register('inquiry')}
				placeholder={
					!taxRefund
						? `도입문의, 서비스 이용 절차 등 궁금하신 사항을 남겨주세요.\n문의 내용을 확인하여 빠르게 연락드리도록 하겠습니다.`
						: `도입문의, 서비스 이용 절차 등 궁금하신 사항을\n남겨주세요. 문의 내용을 확인하여\n빠르게 연락드리도록 하겠습니다.`
				}
				label='문의내용'
				isRequired
				margin={taxRefund ? [0] : [0, 0, 50]}
				error={errors.inquiry}
				maxLength={1000}
			/>
		</Form>
	);
}
const StyledFlexWrap = styled(FlexWrap)<{ taxRefund?: boolean }>`
	gap: ${(props) => (props.taxRefund ? '1.25rem' : '100px')};
	@media ${(props) => props.theme.tablet} {
		flex-direction: column;
		gap: 0;
	}
`;
const Form = styled.form<{ taxRefund?: boolean }>`
	margin-top: 3.5rem;
	${(props) =>
		props.taxRefund &&
		css`
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			color: #fff;
		`}
	@media ${(props) => props.theme.tablet} {
		margin-top: 1.94rem;
	}
`;

export default FormSection;
