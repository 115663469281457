import React from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router';

import HomeBannerMobileWebp from 'assets/bg/mobile/m_homeBanner.webp';
import HomeBannerMobile from 'assets/bg/mobile/m_homeBanner.png';
import BrowserTitle from 'components/_common/BrowserTitle';
import TopBanner from 'components/_common/TopBanner';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import ServiceGuidePng from 'assets/bg/pc/serviceGuide.png';
import ServiceGuideWebp from 'assets/bg/pc/serviceGuide.webp';
import { checkWebpSupport } from 'utils/checkWebpSupport';
import { refundRoutes } from 'routes';
import TabButton from 'components/_common/TabButton';

const links = [
	{
		text: '매장 환급',
		link: refundRoutes.taxRefund,
		pathname: '/refund',
	},
	{
		text: '병원 환급',
		link: refundRoutes.medical,
		pathname: '/refund/medical',
	},
	{
		text: '해외 간편 결제',
		link: refundRoutes.globalPayment,
		pathname: '/refund/global-payment',
	},
];

function Refund() {
	return (
		<MainContainerLayout>
			<BrowserTitle
				title="케이티피 | 서비스 안내"
				description="사후면세제도는 택스리펀, 텍스프리 판매라고 하며 관광객 대상 부가가치세 환급을 통해 추가 매출을 올릴 수 있는 효과적인 전략입니다."
				url="https://www.ktaxpay.com/refund"
			/>
			<TopBanner
				title="서비스 안내"
				content={`외국인 관광객이 찾아오는 텍스프리(TAX FREE) 매장,\n더 많은 고객은 더 많은 매출을 만듭니다!`}
				imgUrl={
					isMobile
						? checkWebpSupport(HomeBannerMobileWebp, HomeBannerMobile)
						: checkWebpSupport(ServiceGuideWebp, ServiceGuidePng)
				}
			/>
			<TabButton links={links} />
			<Outlet />
		</MainContainerLayout>
	);
}

export default Refund;
