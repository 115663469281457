import { SlideUp } from 'animation';
import FlexWrap from 'components/_common/FlexWrap';
import MedicalGuideItem from 'components/Refund/MedicalGuideItem';
import { SERVICE_INTRODUCE } from 'constants/Medical';
import useObserve from 'hooks/useObserve';
import styled, { css } from 'styled-components';
import { Title48 } from 'styles';

function ServiceIntroduce() {
	const { ref, isActive } = useObserve();
	const { ref: firstRef, isActive: firstIsActive } = useObserve();
	const { ref: secondRef, isActive: secondIsActive } = useObserve();
	const { ref: thirdRef, isActive: thirdIsActive } = useObserve();
	const { ref: fourthRef, isActive: fourthIsActive } = useObserve();
	const refList = [firstRef, secondRef, thirdRef, fourthRef];
	const isActiveList = [
		firstIsActive,
		secondIsActive,
		thirdIsActive,
		fourthIsActive,
	];

	const makeIntroduceList = () => {
		return SERVICE_INTRODUCE.map((introduce, index) => {
			return {
				...introduce,
				ref: refList[index],
				isActive: isActiveList[index],
			};
		});
	};

	return (
		<Wrapper>
			<AnimationWrapper ref={ref} isActive={isActive}>
				<Title48>KTP의 혁신적인 서비스를 경험해보세요.</Title48>
				<Notification>
					택스 리펀은 가맹비 및 설치비가 들지 않는 효과적인 외국인 환자 모객
					방법입니다.
				</Notification>
			</AnimationWrapper>
			<List>
				{makeIntroduceList().map((item, idx) => (
					<AnimationWrapper ref={item.ref} isActive={item.isActive}>
						<MedicalGuideItem
							key={idx}
							item={item}
							idx={idx}
							length={SERVICE_INTRODUCE.length}
						/>
					</AnimationWrapper>
				))}
			</List>
		</Wrapper>
	);
}

export default ServiceIntroduce;

const Wrapper = styled.section`
	padding: 200px 0;
`;

const AnimationWrapper = styled.div<{ isActive: boolean }>`
	opacity: 0;
	${(props) =>
		props.isActive &&
		css`
			animation: ${SlideUp} 400ms ease-in forwards;
		`}
`;

const Notification = styled.small`
	display: block;
	color: #80848a;
	text-align: center;
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: -0.48px;
	word-break: keep-all;
	@media ${(props) => props.theme.tablet} {
		font-size: 1.2rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 1rem;
	}
`;

const List = styled(FlexWrap)`
	flex-direction: column;
	width: 100%;
`;
