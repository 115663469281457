import { SlideUp } from 'animation';
import useObserve from 'hooks/useObserve';
import React from 'react';
import styled, { css } from 'styled-components';
import ICON_HUNAM_GRAY from 'assets/medical/icon_human_gray.png';
import ICON_HUNAM_BLUE from 'assets/medical/icon_human_blue.png';

const GRAPH_DATA = [
	{
		year: '2020',
		count: 117,
	},
	{
		year: '2021',
		count: 145,
	},
	{
		year: '2022',
		count: 248,
	},
	{
		year: '2023',
		count: 606,
	},
];

function CustomerGraph() {
	const { ref, isActive } = useObserve();
	const { ref: graphRef, isActive: GraphIsActive } = useObserve();

	const getHeightPixels = (count: number) => {
		const baseHeight = window.innerWidth <= 576 ? 250 : 450;
		const percentage = Math.floor((count / 606) * 100);
		const pixels = Math.floor((baseHeight * percentage) / 100);
		return pixels;
	};

	return (
		<section>
			<Title ref={ref} isActive={isActive}>
				요즘 외국인 환자가 늘어나는데
				<br />
				<Keyword>고객 만족도</Keyword>를 어떻게 높일까?
			</Title>
			<ContentsWrapper>
				<GraphList ref={graphRef}>
					{GRAPH_DATA.map((graph, index) => {
						return (
							<div>
								<Count
									isLatest={GRAPH_DATA.length - 1 === index}
									isActive={GraphIsActive}
									delay={500 * index}
								>
									{graph.count}
								</Count>
								<GraphWrapper height={getHeightPixels(graph.count)}>
									<Graph
										isActive={GraphIsActive}
										delay={300 * index}
										isLatest={GRAPH_DATA.length - 1 === index}
									></Graph>
								</GraphWrapper>
								<Years isLatest={GRAPH_DATA.length - 1 === index}>
									{graph.year}
								</Years>
							</div>
						);
					})}
				</GraphList>
				<Notification>
					보건복지부 | 코로나 이후 외국인환자 유치 실적 (단위 : 천명)
				</Notification>
				<MemberList>
					<dl>
						<MemberYear color='gray'>2020년도</MemberYear>
						<MemberGray>
							<ImgGray src={ICON_HUNAM_GRAY} alt='' width={40} />
							<span>11만명 방문</span>
						</MemberGray>
					</dl>
					<dl>
						<MemberYear color='blue'>2023년도</MemberYear>
						<MemberBlue>
							<ImgBlue src={ICON_HUNAM_BLUE} alt='' width={48} />
							<span>60만명 방문</span>
						</MemberBlue>
					</dl>
				</MemberList>
			</ContentsWrapper>
		</section>
	);
}

export default CustomerGraph;

const Title = styled.h2<{ isActive: boolean }>`
	color: #3a3b3e;
	font-size: 5rem;
	font-weight: 800;
	line-height: 140%;
	letter-spacing: -1.68px;
	text-align: center;
	opacity: 0;
	${(props) =>
		props.isActive &&
		css`
			animation: ${SlideUp} 400ms ease-in forwards;
		`}
	@media ${(props) => props.theme.tablet} {
		font-size: 3rem;
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 2rem;
		margin-top: 80px;
	}
`;

const Keyword = styled.span`
	color: #246cf6;
`;

const ContentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 200px;
	@media ${(props) => props.theme.mobile} {
		margin-top: 80px;
	}
`;

const GraphList = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 46px;
	height: 500px;
	@media ${(props) => props.theme.mobile} {
		gap: 24px;
		height: 350px;
	}
`;

const GraphWrapper = styled.div<{ height: number }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: ${({ height }) => `${height}px`};
	margin: 12px 0;
	@media ${(props) => props.theme.mobile} {
		margin: 8px 0;
	}
`;

const Graph = styled.div<{
	isActive?: boolean;
	isLatest?: boolean;
	delay: number;
}>`
	border-radius: 100px 100px 0px 0px;
	width: 80px;
	height: 0px;
	${({ isLatest }) =>
		isLatest
			? css`
					background: linear-gradient(180deg, #246cf6 0%, #fff 100%);
			  `
			: css`
					background: linear-gradient(180deg, #e9f0fe 0%, #fff 93.89%);
			  `}
	${({ isActive, delay }) =>
		isActive &&
		css`
			animation: graphIncrease 600ms ${delay}ms linear forwards;
		`}
	@keyframes graphIncrease {
		0% {
			height: 20px;
		}
		100% {
			height: 100%;
		}
	}
	@media ${(props) => props.theme.mobile} {
		width: 40px;
	}
`;

const Years = styled.p<{ isLatest?: boolean }>`
	color: ${({ isLatest }) => (isLatest ? '#246CF6' : '#d3e2fd')};
	text-align: center;
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 140%;
	letter-spacing: -0.48px;
	@media ${(props) => props.theme.mobile} {
		font-size: 1.1rem;
	}
`;

const Count = styled.p<{
	isActive?: boolean;
	isLatest?: boolean;
	delay: number;
}>`
	border-radius: 12px;
	opacity: 0;
	${({ isLatest }) =>
		isLatest
			? css`
					background-color: #246cf6;
					color: #f4f8ff;
					text-align: center;
					font-size: 2rem;
					font-weight: 800;
					letter-spacing: -0.72px;
					padding: 10px 10px 6px;
			  `
			: css`
					color: #d3e2fd;
					text-align: center;
					font-size: 1.5rem;
					font-weight: 600;
					letter-spacing: -0.48px;
			  `}
	${({ isActive, delay }) =>
		isActive &&
		css`
			animation: showCount 300ms ${delay}ms linear forwards;
		`}
	@keyframes showCount {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@media ${(props) => props.theme.mobile} {
		font-size: 1.4rem;
	}
`;

const Notification = styled.p`
	display: block;
	color: #a7c4fb;
	text-align: center;
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 140%;
	letter-spacing: -0.36px;
	margin: 35px 0 100px;
	@media ${(props) => props.theme.mobile} {
		margin: 35px 0 64px;
		font-size: 1rem;
	}
`;

const MemberList = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 50px;
	@media ${(props) => props.theme.mobile} {
		gap: 24px;
	}
`;

const MemberYear = styled.dt<{ color: 'gray' | 'blue' }>`
	margin-bottom: 12px;
	color: ${({ color }) => (color === 'gray' ? '#80848a' : '#246CF6')};
	text-align: center;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: -0.48px;
`;

const MemberGray = styled.dd`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px;
	color: #80848a;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: -0.48px;
	border-radius: 12px;
	background: #f5f6f7;
	@media ${(props) => props.theme.mobile} {
		font-size: 1.2rem;
	}
`;

const MemberBlue = styled.dd`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 12px;
	color: #246cf6;
	text-align: center;
	font-size: 2rem;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: -0.48px;
	border-radius: 12px;
	background: #e9f0fe;
	@media ${(props) => props.theme.mobile} {
		font-size: 1.4rem;
	}
`;

const ImgGray = styled.img`
	width: 40px;
	height: 40px;
	@media ${(props) => props.theme.mobile} {
		width: 24px;
		height: 24px;
	}
`;

const ImgBlue = styled.img`
	width: 48px;
	height: 48px;
	@media ${(props) => props.theme.mobile} {
		width: 36px;
		height: 36px;
	}
`;
