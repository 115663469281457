import React from 'react';
import revenue2Img from 'assets/medical/opacityBgRevenue2.png';
import styled, { css } from 'styled-components';
import { Title48 } from 'styles';
import { WITH_KTP_IMG1, WITH_KTP_IMG2 } from 'constants/Medical';

function WithKTP() {
	return (
		<Wrapper>
			<RevenueImg src={revenue2Img} />
			<Title48>많은 병원들이 KTP와 함께하고 있습니다.</Title48>
			<CardWrapper>
				<Line>
					{WITH_KTP_IMG1.map((img) => {
						return (
							<Card>
								<img src={img.src} alt={img.alt} />
							</Card>
						);
					})}
				</Line>
				<Line isReverse>
					{WITH_KTP_IMG2.map((img) => {
						return (
							<Card>
								<img src={img.src} alt={img.alt} />
							</Card>
						);
					})}
				</Line>
			</CardWrapper>
		</Wrapper>
	);
}

export default WithKTP;

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 64px;
	width: 100%;
`;

const RevenueImg = styled.img`
	width: 22.9375rem;
	height: 2.312rem;
	object-fit: contain;
`;

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	overflow: hidden;
`;

const Line = styled.ul<{ isReverse?: boolean }>`
	display: flex;
	gap: 16px;
	animation: ${({ isReverse }) => (isReverse ? 'SlideLeft' : 'SlideRight')} 25s
		linear infinite forwards;
	@keyframes SlideRight {
		0% {
			transform: translateX(-1944px);
		}
		100% {
			transform: translateX(0);
		}
	}
	@keyframes SlideLeft {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-1944px);
		}
	}
`;

const Card = styled.li`
	display: flex;
	border-radius: 12px;
	border: 1px solid var(--Mono-10, #e5e6e8);
	min-width: 200px;
	height: 76px;
	padding: 10px 36px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
