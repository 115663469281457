import { checkWebpSupport } from 'utils/checkWebpSupport';

import TaxRefundPng from 'assets/refund/taxRefund/top_banner.png';
import TaxRefundWebp from 'assets/refund/taxRefund/top_banner.webp';
import MedicalPng from 'assets/refund/medical/top_banner.png';
import MedicalWebp from 'assets/refund/medical/top_banner.webp';
import TaxRefundStepOnePng from 'assets/refund/taxRefund/step_1.png';
import TaxRefundStepTwoPng from 'assets/refund/taxRefund/step_2.png';
import TaxRefundStepThreePng from 'assets/refund/taxRefund/step_3.png';
import TaxRefundStepOneWebp from 'assets/refund/taxRefund/step_1.webp';
import TaxRefundStepTwoWebp from 'assets/refund/taxRefund/step_2.webp';
import TaxRefundStepThreeWebp from 'assets/refund/taxRefund/step_3.webp';
import MedicalStepOnePng from 'assets/refund/medical/step_1.png';
import MedicalStepTwoPng from 'assets/refund/medical/step_2.png';
import MedicalStepThreePng from 'assets/refund/medical/step_3.png';
import MedicalStepOneWebp from 'assets/refund/medical/step_1.webp';
import MedicalStepTwoWebp from 'assets/refund/medical/step_2.webp';
import MedicalStepThreeWebp from 'assets/refund/medical/step_3.webp';

import GlobalStepOnePng from 'assets/globalPay/step_1.png';
import GlobalStepTwoPng from 'assets/globalPay/step_2.png';
import GlobalStepThreePng from 'assets/globalPay/step_3.png';
import GlobalStepOneWebp from 'assets/globalPay/step_1.webp';
import GlobalStepTwoWebp from 'assets/globalPay/step_2.webp';
import GlobalStepThreeWebp from 'assets/globalPay/step_3.webp';

import { isMobile } from 'react-device-detect';

export const TaxRefundContent = {
	infoSection: {
		taxRefund: {
			image: checkWebpSupport(TaxRefundWebp, TaxRefundPng),
			alt: '매장 텍스리펀.',
			title: '택스리펀(TAX REFUND)이란?',
			content: `외국인 관광객이 구매한 상품의 부가세를 환급해 주는 제도입니다.${
				isMobile ? ' ' : '\n'
			}금액 할인 효과가 있어 외국인 관광객을 사로잡을 수 있어요!`,
		},
		medical: {
			image: checkWebpSupport(MedicalWebp, MedicalPng),
			alt: '병원 텍스리펀.',
			title: '병원에서도 텍스리펀이 되나요?',
			content: `텍스리펀은 외국인 환자 유치 활성화를 위한 제도로\n치과, 성형외과, 피부과 등에서 17가지 비과세 시술을 텍스리펀 할 수 있어요!`,
		},
	},
	processSection: {
		taxRefund: [
			{
				title: `외국인 관광객\n면세판매장 지정신청`,
				keyword: '첫번째,',
				linkUrl:
					'https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index_pp.xml',
				linkLabel: '지정 신청하기',
				content: {
					explain: `홈택스 또는 KTP 신청 대행 서비스를 통해\n면세판매장 지정신청을 해주세요.`,
				},
				image: checkWebpSupport(TaxRefundStepOneWebp, TaxRefundStepOnePng),
			},
			{
				title: `KTP 앱\n다운로드 및 회원가입`,
				keyword: '두번째,',
				content: {
					explain: `스마트폰, 태블릿 등 다양한 단말기에서\n앱 설치 후 바로 사용할 수 있어요!`,
					items: [`KTP\n앱 설치`, '회원가입', `가맹점\n신청`],
				},
				image: checkWebpSupport(TaxRefundStepTwoWebp, TaxRefundStepTwoPng),
			},
			{
				title: `홍보물 설치`,
				keyword: '세번째,',
				content: {
					explain: `입간판, 현수막, 스티커, 깃발 등\n매장 홍보물을 무료로 제공해드려요!\n외국인 손님을 사로잡아보세요.`,
				},
				image: checkWebpSupport(TaxRefundStepThreeWebp, TaxRefundStepThreePng),
			},
		],
		medical: [
			{
				title: `외국인 환자 유치기관 등록`,
				keyword: '첫번째,',
				linkUrl: 'https://www.medicalkorea.or.kr/korp/main.do',
				linkLabel: '등록하러 가기',
				content: {
					explain: `KTP 서비스 가입을 위해\n메디컬 코리아(한국보건산업진흥원)에서\n유치기관 등록을 먼저해주세요.`,
				},
				image: checkWebpSupport(MedicalStepOneWebp, MedicalStepOnePng),
				notification:
					'* 외국인 환자를 유치하려는 의료기관에게 일정한 요건을 갖추어 등록하게 하는 제도로 택스프리 도입을 위한 필수 요건입니다.',
			},
			{
				title: `KTP 서비스 이용 신청`,
				keyword: '두번째,',
				content: {
					explain: `외국인 환자 유치기관 등록이 완료 되었다면,\nKTP 홈페이지에서 문의하기 또는\n전화 상담을 이용해주세요!`,
				},
				image: checkWebpSupport(MedicalStepTwoWebp, MedicalStepTwoPng),
				number: '02-6275-8011',
			},
			{
				title: `병원 전용\n택스프리 웹사이트 사용`,
				keyword: '세번째,',
				content: {
					explain: `별도 기기 필요 없이\nKTP에서 제공하는 웹 사이트를 통해\n텍스리펀을 제공할 수 있어요.`,
				},
				image: checkWebpSupport(MedicalStepThreeWebp, MedicalStepThreePng),
			},
		],
	},
};

export const globalPayContentList = [
	{
		title: `계약서 및 이용 신청서\n다운로드`,
		keyword: '첫번째,',
		action: 'DOWNLOAD',
		content: `해외 간편 결제 (큐릭) 이용 신청서 1부\n해외 간편 결제 (큐릭) 이용 계약서 1부`,
		image: checkWebpSupport(GlobalStepOneWebp, GlobalStepOnePng),
	},
	{
		title: `서류 작성 후 \n메일 전송`,
		keyword: '두번째,',
		action: 'COPY_LINK',
		content: `서류 작성을 완료하시면\n촬영하거나 스캔해서 메일로 보내주세요.`,
		image: checkWebpSupport(GlobalStepTwoWebp, GlobalStepTwoPng),
	},
	{
		title: `KTP 앱 다운로드`,
		keyword: '마지막,',
		content: `별도 기기 추가 설치 없이\nKTP 앱 다운 받고\n해외 간편 결제를 바로 사용하세요!`,
		image: checkWebpSupport(GlobalStepThreeWebp, GlobalStepThreePng),
	},
];
