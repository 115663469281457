import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { MedicalHomeSelectButton } from 'constants/Medical';
import useDeviceCheck from 'hooks/useDeviceCheck';

function TopSelectButton() {
  const { pathname } = useLocation();
  const { isTablet } = useDeviceCheck();

  return (
    <Container>
      {MedicalHomeSelectButton.map(({ title, link, imgSrc, iconSrc, tabletTitle }) => (
        <StyledLink key={title} to={link} isActive={pathname === link}>
          <Img src={imgSrc} isActive={pathname === link} />
          <Icon src={iconSrc} isActive={pathname === link} />
          <Text isActive={pathname === link}>{isTablet ? tabletTitle : title}</Text>
        </StyledLink>
      ))}
    </Container>
  );
}

export default TopSelectButton;

const Container = styled.div`
  position: absolute;
  bottom: -8rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0.75rem;
  gap: 2rem;

  @media ${(props) => props.theme.tablet} {
    bottom: -1.5rem;
  }
`;

const activeStyle = css`
  color: #1856cd;
  filter: grayscale(0%);
  @media ${(props) => props.theme.tablet} {
    background-color: #f4f8ff;
  }
`;

const StyledLink = styled(Link)<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 15.625rem;
  gap: 1rem;
  padding: 1.12rem 8rem;
  border-radius: 1rem;
  border: 2px solid rgba(235, 235, 235, 0.1);
  background: rgba(215, 215, 215, 0.4);
  backdrop-filter: blur(15px);
  ${({ isActive }) => isActive && activeStyle}
  @media ${(props) => props.theme.tablet} {
    flex-direction: row;
    gap: 0.75rem;
    padding: 1.5rem 0.75rem;
    border-radius: 62.5rem;
    background-color: #ffffff;
    box-shadow: 3px 3px 12px 0px rgba(39, 52, 124, 0.2);
    width: 10.625rem;
    height: 3rem;
    flex: none;
    ${({ isActive }) => isActive && activeStyle}
  }
`;

const Img = styled.img<{ isActive: boolean }>`
  width: 8.75rem;
  height: 8.75rem;
  opacity: 80%;
  filter: grayscale(100%);
  ${({ isActive }) =>
    isActive &&
    css`
      filter: grayscale(0%);
    `}
  @media ${(props) => props.theme.tablet} {
    display: none;
  }
`;

const Icon = styled.img<{ isActive: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  filter: grayscale(100%);
  ${({ isActive }) =>
    isActive &&
    css`
      filter: grayscale(0%);
    `}
  @media ${(props) => props.theme.desktop} {
    display: none;
  }
`;

const Text = styled.p<{ isActive: boolean }>`
  font-size: 1.3125rem;
  color: #5f6165;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.02625rem;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #1856cd;
    `}
  @media ${(props) => props.theme.tablet} {
    font-size: 1rem;
    font-weight: 600;
  }
`;
