import React, { ReactNode, useEffect, useState } from 'react';
import Header from 'components/_common/Header';
import { useLocation } from 'react-router';
import { isMobile } from 'react-device-detect';
import InquiryFloatingButton from 'components/Home/InquiryFloatingButton';
import InquiryFloatingBar from 'components/Home/InquiryFloatingBar';
import useDeviceCheck from 'hooks/useDeviceCheck';
import ContactButton from 'components/Medical/ContactButton';

interface IProps {
	children: ReactNode;
}
function MainContainerLayout({ children }: IProps) {
	const [isToggle, setIsToggle] = useState(false);
	const [isInquiryFloatingBar, setIsInquiryFloatingBar] = useState(false);
	const { pathname } = useLocation();
	const [isTop, setIsTop] = useState(true);
	const [isBackground, setIsBackground] = useState(true);
	const { isTablet } = useDeviceCheck();
	const onScroll = () => {
		const homeSectionTop = document.getElementById('home-banner')?.offsetHeight;
		const pageSectionTop = document.getElementById('page-banner')?.offsetHeight;

		const sectionHeight = homeSectionTop || pageSectionTop;
		if (!sectionHeight) return;
		if (window.scrollY < sectionHeight - 72) {
			setIsTop(true);
			setIsBackground(true);
		} else {
			setIsTop(false);
			setIsBackground(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);
	return (
		<main>
			<Header
				isTop={
					pathname === '/trial' || pathname === '/refund/global-payment'
						? false
						: isTablet
						? false
						: isTop
				}
				isBackground={isBackground}
			/>
			{children}{' '}
			{['/', '/hotel'].includes(pathname) && isTablet ? (
				<InquiryFloatingButton />
			) : (
				<InquiryFloatingBar
					isInquiryFloatingBar={isInquiryFloatingBar}
					isToggle={isToggle}
					setIsToggle={setIsToggle}
				/>
			)}
			{['/medical', '/'].includes(pathname) && <ContactButton />}
		</main>
	);
}

export default MainContainerLayout;
