export const SURGERY_LEFT = [
	{
		first: '쌍커풀수술',
		second: '코성형수술',
		third: '유방수술',
	},
	{
		first: '치아성형술',
		second: '악안면교정술',
		third: '색소모반주근깨',
	},
	{
		first: '제모술',
		second: '탈모치료술',
		third: '모발이식술',
	},
	{
		first: '지방융해술',
		second: '피부재생술',
		third: '피부미백술',
	},
];

export const SURGERY_RIGHT = [
	{
		first: '지방흡입술',
		second: '주름살제거술',
		third: '안면윤곽술',
	},
	{
		first: '흑색점',
		second: '기미',
		third: '여드름치료술',
	},
	{
		first: '분신술',
		second: '문신제거술',
		third: '피어싱',
	},
	{
		first: '항노화치료술',
		second: '모공축소술',
		third: '미용성형 기타',
	},
];
