import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { KTPServiceConent } from 'constants/Home';
import KTPServiceItem from './KTPServiceItem';
import { useLocation } from 'react-router';

function KTPService() {
	const serviceRef = useRef<HTMLDivElement | null>(null);
	const { hash } = useLocation();

	useEffect(() => {
		if (hash === '#service') {
			serviceRef.current?.scrollIntoView();
		}
	}, []);

	return (
		<Wrapper ref={serviceRef} id='service'>
			<Container>
				{KTPServiceConent.map((service, idx) => (
					<KTPServiceItem key={idx} service={service} />
				))}
			</Container>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;
	background: #f4f8ff;
	padding: 6.75rem 0 13.5rem 0;
	@media ${(props) => props.theme.tablet} {
		flex-direction: column;
		padding: 5.69rem 1.25rem 9.37rem 1.25rem;
	}
`;
const Container = styled(FlexWrap)`
	max-width: 1190px;
	flex-direction: column;
	margin: 0 auto;
	gap: 6.75rem;
	@media ${(props) => props.theme.tablet} {
		gap: 7.31rem;
	}
`;

export default KTPService;
