import StoreVideoFile from 'assets/videos/promotion_video.mp4';
import MedicalVideoFile from 'assets/videos/medicalDummyVideoA.mp4';

import MedicalLinkImg from 'assets/medical/medicalLinkImg.png';
import MedicalIcon from 'assets/medical/MedicalIcon.png';
import StoreLinkImg from 'assets/medical/storeILinkImg.png';
import StoreIcon from 'assets/medical/StoreIcon.png';

import MedicalTaxRefund1 from 'assets/medical/TaxRefund1.png';
import MedicalTaxRefund2 from 'assets/medical/TaxRefund2.png';
import MedicalTaxRefund3 from 'assets/medical/TaxRefund3.png';

import MedicalKTPProcessIcon1 from 'assets/medical/ConsultingIcon.png';
import MedicalKTPProcessIcon2 from 'assets/medical/ContractIcon.png';
import MedicalKTPProcessIcon3 from 'assets/medical/AccountIcon.png';

import IMG_GUIDE1 from 'assets/medical/mainGuide1.png';
import IMG_GUIDE2 from 'assets/medical/mainGuide2.png';
import IMG_GUIDE3 from 'assets/medical/mainGuide3.png';
import IMG_GUIDE4 from 'assets/medical/mainGuide4.png';

import IMG_WITH_KTP1 from 'assets/medical/withKTP/image 144.png';
import IMG_WITH_KTP2 from 'assets/medical/withKTP/image 147.png';
import IMG_WITH_KTP3 from 'assets/medical/withKTP/image 148.png';
import IMG_WITH_KTP4 from 'assets/medical/withKTP/image 149.png';
import IMG_WITH_KTP5 from 'assets/medical/withKTP/image 153.png';
import IMG_WITH_KTP6 from 'assets/medical/withKTP/image 154.png';
import IMG_WITH_KTP7 from 'assets/medical/withKTP/image 156.png';
import IMG_WITH_KTP8 from 'assets/medical/withKTP/image 157.png';
import IMG_WITH_KTP9 from 'assets/medical/withKTP/image 159.png';
import IMG_WITH_KTP10 from 'assets/medical/withKTP/image 160.png';
import IMG_WITH_KTP11 from 'assets/medical/withKTP/image 161.png';
import IMG_WITH_KTP12 from 'assets/medical/withKTP/image 162.png';
import IMG_WITH_KTP13 from 'assets/medical/withKTP/image 163.png';
import IMG_WITH_KTP14 from 'assets/medical/withKTP/image 164.png';
import IMG_WITH_KTP15 from 'assets/medical/withKTP/image 165.png';
import IMG_WITH_KTP16 from 'assets/medical/withKTP/image 166.png';
import IMG_WITH_KTP17 from 'assets/medical/withKTP/image 168.png';
import IMG_WITH_KTP18 from 'assets/medical/withKTP/image 169.png';

import ICON_CS from 'assets/medical/inquiryGuide/icon_cs.png';
import ICON_FILE from 'assets/medical/inquiryGuide/icon_file.png';
import ICON_GLOBE from 'assets/medical/inquiryGuide/icon_globe.png';
import ICON_MONITOR from 'assets/medical/inquiryGuide/icon_monitor.png';

interface VideoBanner {
	video: string;
	title: string;
	subTitle: string;
}

interface MedicalVideoBannerMap {
	[key: string]: VideoBanner;
}

export const MedicalVideoBanner: MedicalVideoBannerMap = {
	'/medical': {
		video: MedicalVideoFile,
		title: '병원 맞춤 간편 택스 리펀',
		subTitle: '사용하던 PC로 간편하게!',
	},
	'/': {
		video: StoreVideoFile,
		title: '내 손 안의 면세점',
		subTitle: '우리 매장을 면세점으로!',
	},
};

export const MedicalHomeSelectButton = [
	{
		title: '매장 TAX FREE 안내',
		tabletTitle: '매장 택스 프리',
		imgSrc: StoreLinkImg,
		iconSrc: StoreIcon,
		value: 'sotre',
		link: '/',
	},
	{
		title: '병원 TAX REFUND 안내',
		tabletTitle: '병원 택스 리펀',
		imgSrc: MedicalLinkImg,
		iconSrc: MedicalIcon,
		value: 'medical',
		link: '/medical',
	},
];

export const MedicalHomeTaxRefund = {
	KOR: [
		{
			title: '고객도 병원도 만족하는\nKTP 택스 리펀드',
			items: [
				{
					title: '택스 리펀드로 추가 비용 없이\n고객 만족도를 높이세요.',
					desc: '택스 리펀드는 가맹비 및 설치비가 들지 않는 효과적인 외국인 고객 모객 방법입니다. ',
					detailedDesc: '*고객에 최대 10% 부가세 환급',
					img: MedicalTaxRefund1,
				},
				{
					title: '번거로운 과정 없이\n환자 시술 정보만으로 끝',
					desc: '병원 PC에서 바로 사용 가능한 전용 웹사이트를 개설해 드립니다. 추가 기기 설치 없이 정보만 입력 하면 바로 택스 리펀드가 완료됩니다. ',
					img: MedicalTaxRefund2,
				},
				{
					title: '공항에서 기다릴 필요 없이\n바로 환급금 지급',
					desc: 'KTP는 송금, 키오스크 현장 환급 등 다양한 환급금 지급 방식을 보유하고 있어 고객이 공항에서 환급을 위해 대기할 필요가 없습니다. ',
					img: MedicalTaxRefund3,
				},
			],
		},
	],
};

export const MedicalKTPIntroProcess = [
	{ title: 'STEP1', icon: MedicalKTPProcessIcon1, desc: '도입 상담' },
	{ title: 'STEP2', icon: MedicalKTPProcessIcon2, desc: '가맹 계약' },
	{ title: 'STEP3', icon: MedicalKTPProcessIcon3, desc: '계정 발급' },
];

export const MedicalQna = [
	{
		question: 'Q. 수수료는 평생 무료인가요?',
		answer:
			'네. KTP 이용 수수료는 평생 무료입니다.\n수수료를 병원에서 수취하지 않고, 외국인 환자가 돌려받는 부가세 중 일부(국세청 지정 요율) 금액을 수취하고 있습니다.',
	},
	{
		question: 'Q. 별도 기계 설치가 필요한가요?',
		answer:
			'아니오, KTP는 별도기기를 설치할 필요가 없습니다.\n기존 텍스리펀드는 영수증 출력기, 여권 스캐너 등 부피를 차지하는 기기를 설치해야 했지만,\nKTP는 병원에서 쉽고 간편하게 이용하실 수 있도록 기기가 필요 없는 PC 웹 기반 서비스를 제공하고 있습니다.',
	},
	{
		question: 'Q. 가맹 신청은 어떻게 하나요?',
		answer:
			'가맹신청 방법은 간단합니다.\nKTP고객센터(02-6275-8011)로 연락주시면 친절한 담당자가 안내를 도와드립니다.\n준비 서류부터 제출 방법까지 차근차근 설명드리고 있으니, 언제든 연락주세요.',
	},
	{
		question: 'Q. 신청하면 언제부터 사용 가능한가요?',
		answer: '가입 신청서를 제출하면 평균 2근무일 이내 이용이 가능합니다.',
	},
	{
		question: 'Q. 택스리펀드 신청 조건이 있나요?',
		answer:
			'택스리펀을 신청하려면 외국인환자 유치기관에 등록 되어있어야 합니다.\n외국인환자 유치기관에 등록 된 병원만 텍스리펀 도입이 가능하기 때문인데요,\n자세한 내용은 메디컬 코리아(https://www.medicalkorea.or.kr/korp)에서 확인해주세요.',
	},
	{
		question: 'Q. 신청 가능 과목 또는 진료항목이 있나요?',
		answer:
			'치과, 성형외과, 피부과 등에서 성형미용을 목적으로하는 비과세 시술만 텍스리펀이 가능합니다.\n텍스리펀 가능 여부 확인은 고객센터(02-6275-8011)로 연락주세요.',
	},
	{
		question: 'Q. 세금처리가 복잡하지 않나요?',
		answer:
			'KTP 가맹 병원은 세금처리가 간편합니다.\nKTP에서 부가세 신고 기간에 병원 별 신고 자료를 제공해드리고 있습니다.\n서류를 세무사에게 전달(직접신고 시 홈텍스에 업로드)만 하시면 간단하게 세금신고를 할 수 있습니다.',
	},
];

export const SERVICE_INTRODUCE = [
	{
		title: `기존에 사용하던 PC로\n간편하게 택스프리 환급웹 사용`,
		keyword: '첫번째,',
		content: {
			explain: `새로운 기기의 도입&설치없이 기존에 사용하던 PC에\n바로 사용 가능한 전용 웹사이트를 개설해 드립니다.\n고객의 정보만 입력하면 택스 리펀이 완료됩니다.`,
		},
		image: IMG_GUIDE1,
	},
	{
		title: `병원 내 환급 키오스크 도입으로\n병원 홍보와 택스프리를 동시에`,
		keyword: '두번째,',
		content: {
			explain: `병원 내 환급 키오스크 도입으로 맞춤형 홍보 콘텐츠 노출과\n택스프리 서비스를 한 번에 이용할 수 있습니다.\n또한, 고객은 공항에서 환급 받을 필요없이 환급 키오스크로 환급 받을 수 있습니다.`,
		},
		image: IMG_GUIDE2,
	},
	{
		title: `낮은 수수료로\n해외 간편결제 서비스 도입`,
		keyword: '세번째,',
		content: {
			explain: `알리페이, 위쳇페이 등\n외국인 맞춤형 해외 간편결제 서비스를 낮은 수수료로\n이용할 수 있어요.`,
		},
		image: IMG_GUIDE3,
	},
	{
		title: `복잡한 서류 정리는\nKTP가 대신`,
		keyword: '네번째,',
		content: {
			explain: `택스리펀에 대한 부가세 신고 자료 처리와 실적 자동 등록으로\n병원 업무를 간소화 해드려요.`,
		},
		image: IMG_GUIDE4,
	},
];

export const WITH_KTP_IMG1 = [
	{
		src: IMG_WITH_KTP1,
		alt: '이즈의원',
	},
	{
		src: IMG_WITH_KTP2,
		alt: '림피부과의원',
	},
	{
		src: IMG_WITH_KTP3,
		alt: '페이스피부과',
	},
	{
		src: IMG_WITH_KTP4,
		alt: '오늘의치과',
	},
	{
		src: IMG_WITH_KTP5,
		alt: '고:슬림',
	},
	{
		src: IMG_WITH_KTP6,
		alt: '루미의원',
	},
	{
		src: IMG_WITH_KTP7,
		alt: 'DOD',
	},
	{
		src: IMG_WITH_KTP8,
		alt: '새론의원',
	},
	{
		src: IMG_WITH_KTP9,
		alt: '웰가성형외과',
	},
	{
		src: IMG_WITH_KTP1,
		alt: '이즈의원',
	},
	{
		src: IMG_WITH_KTP2,
		alt: '림피부과의원',
	},
	{
		src: IMG_WITH_KTP3,
		alt: '페이스피부과',
	},
	{
		src: IMG_WITH_KTP4,
		alt: '오늘의치과',
	},
	{
		src: IMG_WITH_KTP5,
		alt: '고:슬림',
	},
];

export const WITH_KTP_IMG2 = [
	{
		src: IMG_WITH_KTP10,
		alt: '영종센트럴피부과',
	},
	{
		src: IMG_WITH_KTP11,
		alt: '아로하피부과',
	},
	{
		src: IMG_WITH_KTP12,
		alt: 'Clay성형외과',
	},
	{
		src: IMG_WITH_KTP13,
		alt: '신촌다인치과병원',
	},
	{
		src: IMG_WITH_KTP14,
		alt: '로얄라인의원',
	},
	{
		src: IMG_WITH_KTP15,
		alt: '라현의원',
	},
	{
		src: IMG_WITH_KTP16,
		alt: 'wanna성형외과',
	},
	{
		src: IMG_WITH_KTP17,
		alt: 'le dental clinic',
	},
	{
		src: IMG_WITH_KTP18,
		alt: '연세예감치과의원',
	},
	{
		src: IMG_WITH_KTP10,
		alt: '영종센트럴피부과',
	},
	{
		src: IMG_WITH_KTP11,
		alt: '아로하피부과',
	},
	{
		src: IMG_WITH_KTP12,
		alt: 'Clay성형외과',
	},
	{
		src: IMG_WITH_KTP13,
		alt: '신촌다인치과병원',
	},
	{
		src: IMG_WITH_KTP14,
		alt: '로얄라인의원',
	},
];

export const INQUIRY_STEPS = [
	{
		step: 'STEP 1',
		icon: ICON_GLOBE,
		title: '유치기관 등록',
	},
	{
		step: 'STEP 2',
		icon: ICON_CS,
		title: '가맹신청',
	},
	{
		step: 'STEP 3',
		icon: ICON_FILE,
		title: '절차안내',
	},
	{
		step: 'STEP 4',
		icon: ICON_MONITOR,
		title: '서비스도입',
	},
];
